import { HttpInterceptorsConfig } from "./httpInterceptors";
export class UsersService extends HttpInterceptorsConfig {
	async listUsers (filters) {
		const response = await this.axios.get("users/listUsers", { params: { filters }});
		return response.data;
	}

	async upsertUser (payload) {
		const response = await this.axios.post("users/upsertUser", payload);
		return response.data;
	}

	async deleteUser (id) {
		const response = await this.axios.delete("users/deleteUser", { params: id });
		return response.data;
	}

	async updateProfile (payload) {
		const response = await this.axios.post("users/updateProfile", payload);
		return response.data;
	}
}
