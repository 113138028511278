<template>
	<b-form-group :label-align="labelAlign" :label-for="id">
		<b-input-group>
			<b-form-input
				:id="id"
				:name="name"
				class="border-right-0 input-senha"
				:type="typeInput"
				:value="value"
				autocomplete="off"
				@input="v => $emit('input', v)"
				:state="validateState(name)"
				v-validate="{ required: validate }"
				:placeholder="placeholder"
			/>
			<b-input-group-append>
				<button
					type="button"
					class="inlineBtn"
					@click="changeType"
				>
					<img :src="require(typeInput === 'password'
						? '../assets/img/closedEye.svg'
						: '../assets/img/openedEye.svg')" />
				</button>
			</b-input-group-append>
		</b-input-group>
	</b-form-group>
</template>

<script>
	export default {
		props: {
			id: {
				type: String,
				default: "userPassword"
			},
			name: {
				type: String
			},
			value: {
				type: String,
				required: false
			},
			state: {
				type: Boolean,
				default: null
			},
			labelAlign: {
				type: String,
				default: "left"
			},
			validate: {
				type: Boolean,
				default: false
			},
			placeholder: {
				type: String,
				default: ""
			}
		},

		data () {
			return {
				typeInput: "password"
			};
		},

		methods: {
			changeType () {
				this.typeInput = this.typeInput === "password" ? "text" : "password";
			},

			validateState (ref) {
				if (
					this.veeFields[ref]
					&& (this.veeFields[ref].dirty || this.veeFields[ref].validated)
				) {
					return !this.veeErrors.has(ref);
				}

				return null;
			}
		}
	};
</script>

<style scoped>
	.inlineBtn {
		border: 1px solid #ced4da;
		border-radius: 5px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.input-senha {
		font-size: 1rem;
		padding: 6px 12px;
	}
</style>
