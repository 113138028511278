<template>
	<template-base class="containerCard">
		<div>
			<div class="container-head">
				<div v-if="users.length" class="container-pages">
					<b-pagination
						id="page"
						v-model="currentPage"
						:total-rows="pagination.count"
						:per-page="pagination.limit.value"
					></b-pagination>
				</div>
				<div
					v-if="possuiPermissao('GER_I_USUARIOS')"
					class="container-add-new"
					v-bind:class="!users.length ? 'container-add-new-100' : ''"
				>
					<b-button class="add-button" @click="mostrarEdicao()">
						Adicionar
					</b-button>
				</div>
			</div>
			<div v-if="users.length" class="head-row">
				<span class="text-head-row ml-1">
					Resultados {{ pagination.offset + 1 }}-{{ pagination.offset + users.length }} de {{ pagination.count }}
				</span>
				<b-form
					class="text-head-row">
					<label class="mr-1">
						Itens por página:
					</label>
					<b-form-select
						class="mr-1 mb-2"
						v-model="pagination.limit.value"
						name="itens-por-pagina"
						:options="optionsItensPorPag"
						@input="changeItensByPage()"
						:disabled="loading"
					/>
				</b-form>
			</div>
			<b-modal
				ref="modalEdicao"
				:title="titleModalEdicao"
				size="lg"
				dialog-class="modal-dialog-centered"
			>
				<div class="modal-edicao modal-edicao-label">
					<b-row>
						<b-col cols="12" class="text-modal">
							<span class="label-modal">Nome Completo</span>
							<b-form-input
								name="nome"
								class="modal-edicao-input"
								type="text"
								v-model="usuarioSelecionado.nome"
								autocomplete="off"
								placeholder="Digite o seu nome"
								v-validate="{ required: true }"
								:state="validateState('nome')"
								@input="validateForm()"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="6" class="text-modal pr-1">
							<span class="label-modal">E-mail</span>
							<b-form-input
								name="email"
								class="modal-edicao-input"
								type="email"
								v-model="usuarioSelecionado.email"
								autocomplete="off"
								placeholder="Digite o seu e-mail"
								v-validate="{ required: false, email: true }"
								:state="validateState('email')"
								@input="validateForm()"
							/>
						</b-col>
						<b-col cols="6" class="text-modal pl-1">
							<span class="label-modal">Grupo de Usuário</span>
							<b-form-select
								name="id-grupo-usuario"
								class="modal-edicao-input mr-1 mb-2"
								v-model="usuarioSelecionado.id_grupo_usuario"
								v-validate="{ required: true }"
								:state="validateState('id-grupo-usuario')"
								:options="gruposUsuarios"
								:disabled="loading"
								@input="validateForm()"
							>
								<template slot="first">
									<option :value="null">
										Nenhum
									</option>
								</template>
							</b-form-select>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="6" class="text-modal pr-1">
							<span class="label-modal">Usuário</span>
							<b-form-input
								name="usuario"
								class="modal-edicao-input"
								type="text"
								v-model="usuarioSelecionado.usuario"
								autocomplete="off"
								placeholder="Digite um nome de usuário"
								v-validate="{ required: true }"
								:state="validateState('usuario')"
								@input="validateForm()"
							/>
						</b-col>
						<b-col cols="6" class="text-modal pl-1">
							<span class="label-modal">Senha</span>
							<b-form>
								<InputSenha
									id="senha"
									name="senha"
									v-model="usuarioSelecionado.senha"
									@input="validateForm()"
									:validate="noEditUser"
									:placeholder="!noEditUser ? 'Inalterada' : 'Digite a senha'"
								/>
							</b-form>
						</b-col>
					</b-row>
				</div>
				<template #modal-footer>
					<div class="w-100 d-flex justify-content-between">
						<div class="btn-group">
							<b-button class="btn-modal btn-cancel" @click="fecharModal('modalEdicao')">Cancelar</b-button>
							<b-button class="btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
						</div>
						<b-button
							class="btn-modal btn-salvar"
							@click="salvarEdicao"
							:disabled="invalidForm"
						>
							Salvar
						</b-button>
					</div>
				</template>
			</b-modal>
		</div>
		<div v-if="users.length" class="table-responsive">
			<DataTable
				class="data-table"
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:nosearch="true"
				:nofilters="true"
				name="usuarios"
				:hasPagination="true"
				:noedit="true"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_USUARIOS')"
				:permissaoDelete="possuiPermissao('GER_D_USUARIOS')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
			/>
		</div>
		<div v-else class="alert alert-warning" role="alert">
			{{ errMsg }}
		</div>
	</template-base>
</template>

<script>
	import { createHash } from "crypto";

	import DataTable from "@/components/DataTable";
	import InputSenha from "@/components/InputSenha";
	import TemplateBase from "@/templates/Base";

	import { possuiPermissao } from "../../helpers/permissions";
	import { UsersService } from "../../services/users";
	import { GroupsUsersService } from "../../services/groupsUsers";

	export default {
		components: {
			TemplateBase,
			InputSenha,
			DataTable
		},

		inject: ["parentValidator"],

		data () {
			return {
				users: [],
				linhas: [],
				titleModalEdicao: "",
				loading: true,
				gruposUsuarios: [],
				usuarioSelecionado: {
					id: "",
					usuario: "",
					nome: "",
					email: "",
					senha: "",
					id_grupo_usuario: null
				},

				usersService: new UsersService(),
				groupsUsersService: new GroupsUsersService(),

				errMsg: "Nenhum usuário cadastrado!",
				colunas: [
					"Nome Completo",
					"Usuário",
					"E-mail",
					"Grupo de Usuário"
				],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],
				invalidForm: true,
				noEditUser: false,
				possuiPermissao
			};
		},

		watch: {
			currentPage () {
				this.changePage(this.currentPage);
			}
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			this.gruposUsuarios = await this.optionsGruposUsuarios();
			this.findUsers();
		},

		methods: {
			mostrar () {
				if (this.$refs.senha.type === "text")
					this.$refs.senha.type = "password";
				else
					this.$refs.senha.type = "text";
			},

			async optionsGruposUsuarios () {
				const gruposUsuarios = (await this.groupsUsersService.listGroupsUsers({ offset: null, limit: null })).rows || [];
				return gruposUsuarios.reduce((acc, gu) => [
					...acc,
					{
						value: gu.id,
						text: gu.nome
					}
				], []);
			},

			changePage () {
				this.pagination.page = this.currentPage;
				this.findUsers();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findUsers();
			},

			async findUsers () {
				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const result = await this.usersService.listUsers({ offset, limit }) || {};
				this.users = result.rows || [];
				this.linhas = result.rows.reduce((acc, user) => [
					...acc,
					{
						id: user.id,
						cols: [
							user.nome,
							user.usuario,
							user.email,
							(this.gruposUsuarios.filter(gu => gu.value === user.id_grupo_usuario))[0].text || ""
						]
					}
				], []);
				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
			},

			mostrarEdicao (item) {
				let itemEdit;
				this.invalidForm = true;
				this.titleModalEdicao = "Cadastrar Novo Usuário";
				this.noEditUser = true;

				if (item && item.id) {
					itemEdit = this.users.find(user => user.id === item.id);
					this.titleModalEdicao = "Editar Usuário";
					this.noEditUser = false;
				}

				this.usuarioSelecionado.id = itemEdit?.id || "";
				this.usuarioSelecionado.usuario = itemEdit?.usuario || "";
				this.usuarioSelecionado.nome = itemEdit?.nome || "";
				this.usuarioSelecionado.email = itemEdit?.email || "";
				this.usuarioSelecionado.id_grupo_usuario = itemEdit?.id_grupo_usuario || null;
				this.$refs.modalEdicao.show();
			},

			async confirmaApagar (item_) {
				const item = this.users.find(user => user.id === item_.id);
				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja remover o usuário?",
					type: "warning",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.id);
			},

			async salvarEdicao () {
				const invalidFields = await this.validateModal();
				if (invalidFields) {
					await this.$swal({
						title: "Formulário Inválido",
						text: "Existe informação sem preencher!",
						type: "error",
						showCancelButton: false,
						confirmButtonColor: "#DD6B55",
						confirmButtonText: "OK",
						reverseButtons: true
					});
					return;
				}

				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				const payload = {
					nome: this.usuarioSelecionado.nome,
					email: this.usuarioSelecionado.email,
					usuario: this.usuarioSelecionado.usuario,
					senha: this.usuarioSelecionado.senha ? createHash("sha512").update(this.usuarioSelecionado.senha).digest("hex") : "",
					id_grupo_usuario: this.usuarioSelecionado.id_grupo_usuario
				};

				// ID será enviado apenas se necessário
				if (Number.isInteger(this.usuarioSelecionado.id)) {
					payload.id = this.usuarioSelecionado.id;
				}

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						await this.usersService.upsertUser(payload);

						this.fecharModal("modalEdicao");
						this.findUsers();

						return {
							title: "Sucesso!",
							body: "Usuário salvo com sucesso.",
							config
						};
					} catch (error) {
						throw {
							title: "Falha!",
							body: "Erro ao tentar salvar o usuário!",
							config
						};
					}
				});
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
				this.limparModal();
				this.usuarioSelecionado.id = "";
			},

			async apagar (id) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						await this.usersService.deleteUser({ id });
						this.findUsers();

						return {
							title: "Sucesso!",
							body: "Usuário apagado com sucesso.",
							config
						};
					} catch (error) {
						throw {
							title: "Falha!",
							body: "Erro ao tentar apagar o usuário.",
							config
						};
					}
				});
			},

			limparModal () {
				this.usuarioSelecionado.nome = "";
				this.usuarioSelecionado.email = "";
				this.usuarioSelecionado.usuario = "";
				this.usuarioSelecionado.senha = "";
				this.usuarioSelecionado.id_grupo_usuario = null;
			},

			validateState (ref) {
				if (
					this.veeFields[ref]
					&& ( this.veeFields[ref].dirty || this.veeFields[ref].validated )
				) {
					return !this.veeErrors.has(ref);
				}

				return null;
			},

			async validateModal () {
				const validationFields = Object.keys(this.veeFields);
				if (!validationFields.length)
					return true;

				const validations = validationFields.reduce((acc, ref) => {
					if (this.veeFields[ref] && this.veeFields[ref].invalid)
						return [...acc, this.veeFields[ref].invalid];

					return acc;
				}, []);

				const invalidFields = validations.filter(field => field);
				return Boolean(invalidFields && invalidFields.length);
			},

			async validateForm () {
				const formFields = Object.keys(this.usuarioSelecionado);
				const invalidFields = formFields.reduce((acc, ff) => {
					if (ff === "email" || ff === "id" || this.usuarioSelecionado[ff] || (!this.noEditUser && ff === "senha"))
						return acc;

					return [ ...acc, ff ];
				}, []);

				this.invalidForm = Boolean(invalidFields.length);
			}
		}
	};
</script>

<style scoped>
	.text-modal {
		font-size: 20px;
	}

	.label-modal {
		font-size: 1rem;
		color: #000000 !important;
	}

	label {
		width: 160px;
	}

	.container-head {
		display: flex;
		height: 5rem;
		width: 100%;
	}

	.container-pages {
		display: flex;
		width: 70%;
	}

	.container-add-new {
		display: flex;
		width: 30%;
		height: 3rem;
		justify-content: right;
	}

	.add-button {
		width: 135;
		height: 43;
		background-color: #218838;
		color: #FFFFFF;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		padding: .75rem 1.5rem;
		border-style: none;
		margin-bottom: .5rem;
		font-weight: 700;
	}

	.containerCard {
		max-width: 90.5%;
	}

	.head-row {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		margin-top: -16px;
	}

	.text-head-row {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1rem;
		color: #000000;
		white-space: nowrap;
	}

	.modal-edicao-label {
		font-size: 23px;
	}

	.modal-edicao-input {
		font-size: 1rem;
	}

	.btn-modal {
		font-size: 1rem;
		color: white;
	}

	.btn-cancel {
		background-color: #343A40;
		border-radius: 4px 0 0 4px;
		border-style: none;
	}

	.btn-limpar {
		background-color: #6C757D;
		border-radius: 0 4px 4px 0;
		border-style: none;

	}

	.btn-salvar {
		background-color: #28A745;
		width: 168px;
		border-radius: 4px;
		border-style: none;
	}

	.table-body-detalhe {
		padding: 5px 0;
	}

	.container-add-new-100 {
		width: 100% !important;
	}
</style>

<style>
	.form-control {
		height: auto !important;
	}
</style>
